#dropdown-basic {
  background-color: #00b0c9;
  border: 0;
}
.ri {
  color: #00b0c9;
  cursor: pointer;
}
.dropdown-item {
  color: var(--main-color);
}

.dropdown-width {
  min-width: 130px;
}
.dropdown-toggle::after {
  /* background-color: black; */
}
.dropdown-toggle {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
}

.custom-select {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 40px;
}

.custom-select select {
  width: 100%;
  height: 100%;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #e24646;
  text-align: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
}

/* Add custom arrow */
.custom-select::after {
  content: ""; /* Unicode for down arrow */
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-20%);
  pointer-events: none;
  color: #888;
  pointer-events: none;
  width: 18px;
  height: 18px;
  background: url("./Arrow\ -\ Down\ Circle.png") no-repeat center center;
  background-size: contain;
}

/* Optional: Add some padding to the right to avoid text overlap with the arrow */
.custom-select select {
  /* padding-right: 30px; */
}

select {
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
}
