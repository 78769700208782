.button {
  background-color: #00b0c9;
  width: 16.625rem;
}
.primary-color {
  color: #001a70;
}
.secondary-color {
  color: #00b0c9;
}

.Tajawal {
  font-family: "Tajawal";
}

/* OVELAY */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00b0c9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
/* OVELAY END */
